@charset "UTF-8";

@import "./variables";
@import "./layout";
@import "./functions";
@import "./object";

//---------------------------------------------------------------
// 全ページで使用する共通CSS
//---------------------------------------------------------------


.l-renewal-content {

  .hover:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  #contents-wrapper {

    width: 100%;
    padding-bottom: 90px;
    background-color: $bg-base-gray;

    @media screen and (max-width: $media-small) {
      padding: 0 16px 40px;
    }

    /**
     メインコンテンツ箇所関連
     */

    #contents-area {
      margin-top: 72px;
      max-width: 980px;

      // MEMO: 余白系
      //#page-title {
      //  @extend .c-border-bottom-title;
      //
      //  text-align: center;
      //  margin: 24px auto 20px;
      //  padding-bottom: 16px;
      //  color: $text-primary;
      //  font-size: $font-size-xxx;
      //  font-weight: $font-weight-bold;
      //
      //  @media screen and (max-width: $media-small) {
      //    font-size: $font-size-xxl;
      //  }
      //}

      @media screen and (max-width: $media-small) {
        margin-top: 64px;
      }


      #main-contents {
        width: auto;
        padding: 32px 16px 40px 16px;
        background-color: $bg-base-white;
        margin: 0 -16px;

        @media screen and (min-width: $media-small) {
          width: 980px;
          padding: 40px 40px 48px 40px;
          border-radius: $border-radius-card;
          margin: 0;
        }

        &.no-bg {
          background-color: transparent;
          border-radius: 0;
          padding: 40px 16px 48px;
        }

        // ページ自体のセクション
        section.c-section-h1 {
          text-align: center;
          h1 {
            @extend .c-border-bottom-title;

            text-align: center;
            margin: 0 auto 40px;
            padding-bottom: 16px;
            color: $text-primary;
            font-size: $font-size-xxx;
            font-weight: $font-weight-bold;

            @media screen and (max-width: $media-small) {
              font-size: $font-size-xxl;
              margin: 0 auto 32px;
            }
          }
        }

        // 背景色のついた主張強めのセクション
        section.c-section-h2 {
          margin-top: 40px;

          h2 {
            @extend .c-text-header;
            text-align: center;
            padding: 8px 0;
            font-size: 19px;
            background-color: $bg-title;
            margin-bottom: 32px;
          }
        }

        // underlineのみのセクション
        section.c-section-h3 {
          padding: 0 0 40px;

          h3 {
            @extend .c-text-primary;
            @extend .c-border-bold;
            @extend .c-bold;

            font-size: 18px;
            padding-bottom: 8px;
            margin-bottom: 24px;
          }
          @media screen and (max-width: $media-small) {
            h3 {
              margin-bottom: 16px;
            }
          }
        }

        // borderで上下を挟んだセクション
        section.c-section-h4 {
          margin-top: 32px;
          @extend .c-border-normal;

          h4 {
            @extend .c-text-primary;
            @extend .c-border-normal;
            @extend .c-bold;

            font-size: 18px;
            padding-bottom: 8px;
          }

          .c-section-h4-body {
            padding: 16px 0;
          }
        }

        // 細いborderで上下を挟んだセクション
        section.c-section-h5 {
          margin-top: 32px;
          @extend .c-border-light;

          h5 {
            @extend .c-text-primary;
            @extend .c-border-light;
            @extend .c-bold;

            font-size: 18px;
            padding-bottom: 8px;
          }

          .c-section-h5-body {
            padding: 16px 0;
          }
        }

        section.c-section-noTitle {
          padding: 16px;

          @media screen and (min-width: $media-small) {
            padding: 16px 40px;
          }
        }


      }

      #side-menu {
        width: 210px;
        padding: 0 0 32px;
        box-shadow: 0 4px 4px 0 rgba(85, 85, 85, 0.10);
        border-radius: 10px;
        height: fit-content;
        overflow: hidden;
        background-color: $bg-base-white;

        & > section {
          padding: 0 16px;
        }

        .c-bg-userinfo {
          padding: 20px 16px;
        }

        /**
          サイドメニュー表示中の場合のみ、メインコンテンツ表示箇所の領域を計算する
         */
        @media screen and (min-width: $media-small) {
          & ~ #main-contents {
            //width: calc(100% - 210px);
            width: 760px;
          }
        }
      }
    }
  }

  /**

   */
  .user-icon {
    @extend .c-round-img;
    object-fit: cover;
  }

  /**
   パンクズリスト用
   */

  #breadcrumb {
    font-size: 10px;
    padding: 15px 0;
    margin: auto;
    @media (min-width: $media-small) {
      padding: 15px 0;
    }

    #bread {
      &-home:before {
        display: inline;
        content: getIconUrl("icon_home.svg");
        transform: translateY(5px);
      }
    }

    & > li {
      display: inline-flex;
      align-items: center;

      &:not(:last-child):after {
        content: ">";
        color: $text-light-gray;
        margin: auto 10px;
      }

      a {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 8rem;
        display: inline-block;
      }
      @media screen and (min-width: $media-small) {
        a {
          max-width: 30rem;
        }
      }
    }
  }

  /**
    ポップアップ対応
   */
  .popup-bg {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &.popup-show {
      display: block;
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
      z-index: 100;
    }
  }
  .popup {
    visibility: hidden;
    opacity: 0;
    transform: translateY(10px);
    transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);

    &.popup-show {
      display: block;
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
    }

    &-all {
      z-index: 110;
      background-color: rgba(200, 200, 200, 0.7);
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;

      .popup-content {
        @extend .l-margin-auto;
        background-color: $bg-base-white;
        width: 343px;
        padding: 48px 12px;
        border-radius: 12px;
        transform: translateY(50svh) translateY(-50%);
        position: relative;

        @media (min-width: $media-small) {
          width: 600px;
          border-radius: 18px;
          padding: 48px 50px;
        }

        .popup-close {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 10px;
          right: 10px;


          @extend .c-round-img;
          background-color: $bg-primary;
          width: 33px;
          height: 33px;
          font-size: 30px;
          color: $text-white;
          border: none;

          &:hover {
            cursor: pointer;
          }

          span {
            position: absolute;
            display: block;
            width: 24px;
            height: 3px;
            background-color: $text-white;
          }

          span:nth-child(1) {
            transform: rotate(-45deg);
          }

          span:nth-child(2) {
            transform: rotate(45deg);
          }

          //&:before {
          //  content: "";
          //  display: inline-block;
          //  width: 24px;
          //  height: 3px;
          //  background-color: $text-white;
          //  transform: rotate(-45deg) translateY(-9px) translateX(15px);
          //}
          //
          //&:after {
          //  content: "";
          //  display: inline-block;
          //  width: 24px;
          //  height: 3px;
          //  background-color: $text-white;
          //  transform: rotate(45deg) translateY(-47px) translateX(-40px);
          //}
        }
      }
    }
  }


  /**
    アイコン関連
   */

  span.icon.diamond,
  i {
    &.diamond {
      content: getIconUrl("icon_diamond.svg");

      &.not-archived {
        content: getIconUrl("icon_diamond_disabled.svg");
      }
    }

    &.diamond-noshadow {
      content: getIconUrl("icon_diamond_noshadow.svg");
    }

    &.people {
      content: getIconUrl("icon_people.svg");
      width: 11px;
      height: 11px;
      @media (min-width: $media-small) {
        width: 14px;
        height: 14px;
      }
    }

    &.instagram {
      content: getIconUrl("icon_instagram.png");
      width: 18px;
    }

    &.instagram-shape {
      content: getIconUrl("icon_instagram_shape.svg");
    }

    &.tiktok,
    &.TikTok {
      content: getIconUrl("icon_tiktok.svg");
      width: 18px;
    }

    &.x, &.twitter {
      content: getIconUrl("icon_x.svg");
    }

    &.blog {
      content: getIconUrl("icon_blog.svg");
    }

    &.timer {
      content: getIconUrl("icon_timer.svg");
      width: 12px;
      height: 12px;
      @media (min-width: $media-small) {
        width: 14px;
        height: 14px;
      }
    }

    &.gray-arrow {
      content: getIconUrl("icon_gray_arrow_triangle.svg");
    }

    &.attention {
      content: getIconUrl("icon_attention.svg");
    }

    &.ok {
      content: getIconUrl("icon_ok.svg");
    }

    &.ng {
      content: getIconUrl("icon_ng.svg");
    }

    &.slider-left {
      content: getIconUrl("icon_slider_left.svg");
    }

    &.slider-right {
      content: getIconUrl("icon_slider_right.svg");
    }

    &.question {
      content: getIconUrl("icon_question.svg");
    }

    &.float-menu-present {
      content: getIconUrl("icon_floatmenu_present.svg");
    }

    &.float-menu-message {
      content: getIconUrl("icon_floatmenu_message.svg");
    }

    &.float-menu-event {
      content: getIconUrl("icon_floatmenu_event.svg");
    }

    &.sort_arrow {
      content: getIconUrl("icon_sort_arrow.svg");
    }

    &.narrow {
      content: getIconUrl("icon_narrow.svg");
    }
  }


  .section-title {
    @extend .c-text-header;

    font-size: 19px;
    background-color: $bg-title;
  }

  /**

   */
  .event-item-info {

    & > p {
      margin-bottom: 8px;
    }

    .c-button {
      padding: 4px 8px;
      font-size: 10px;
      border-radius: 4px;
      font-weight: 700;

      &.type- {

        &item {
          background-color: $bg-gray-blue;
          border-color: $bg-gray-blue;
          color: $text-white;
          font-weight: bold;
        }

        &point {
          background-color: $bg-gray-green;
          border-color: $bg-gray-green;
          color: $text-white;
          font-weight: bold;
        }

        &item-point {
          background-color: $bg-gray-pink;
          border-color: $bg-gray-pink;;
          color: $text-white;
          font-weight: bold;
        }

        &wChance {
          background-color: $bg-rose-pink;
          border-color: $bg-rose-pink;
          color: $text-white;
          font-weight: bold;
        }
      }


      &.event- {
        &monitor,
        &item,
        &questionnaire,
        &theme,
        &purchase {
          @extend .c-icon;

          & > span {
            font-size: $font-size-xs-plus1;
          }

          &:before {
            position: absolute;
            top: 3px;
            left: 5px;
            width: 15px;
            height: 15px;
          }
        }

        &monitor:before {
          background-image: getIconUrl("icon_present.svg");
          padding-top: 4px;
        }

        &item:before {
          background-image: getIconUrl("icon_package.svg");
          padding-top: 4px;
        }

        &questionnaire:before {
          background-image: getIconUrl("icon_binder.svg");
          padding-top: 3px;
        }

        &theme:before {
          background-image: getIconUrl("icon_megaphone.svg");
          padding-top: 3px;
        }

        &purchase:before {
          background-image: getIconUrl("icon_cart.svg");
          padding-top: 3px;
        }
      }
    }

    .w-chance-box {
      background-color: $bg-sub-light2;
      padding: 10px;

      .title {
        color: $text-primary;
        border-bottom: $border-set-primary;
        font-weight: $font-weight-bold;
      }
    }

    .point {
      @extend .c-bold;
      font-size: 12px;

      @media (min-width: $media-small) {
        font-size: 15px;
      }

      &:before {
        content: "";
        background: getIconUrl("icon_point.svg") no-repeat;
        background-size: contain;
        display: inline-block;
        height: 11px;
        width: 11px;
        transform: translateY(-2px);
        vertical-align: middle;
        margin-right: 5px;
        @media (min-width: $media-small) {
          width: 14px;
          height: 14px;
          transform: translateY(-1px);
        }
      }

      &:after {
        content: "pt";
      }
    }

    .applicants {
      display: flex;
      gap: 5px;
      align-items: baseline;
      line-height: 100%;

      .winning {
        font-size: 15px;
        font-weight: 700;
        line-height: 100%;

        @media (max-width: $media-small) {
          font-size: 12px;
        }

        &:after {
          content: "当選 / ";
          font-size: 12px;
          font-weight: normal;
        }
      }

      .apply {
        font-size: 15px;
        font-weight: 700;
        line-height: 100%;

        @media (max-width: $media-small) {
          font-size: 12px;
        }

        &:after {
          content: "応募中";
          font-size: 12px;
          font-weight: normal;
          @media (max-width: $media-small) {
            font-size: 11px;
          }
        }
      }

      .people-count {
        font-size: 12px;
        font-weight: 700;
        line-height: 100%;

        @media (max-width: $media-small) {
          font-size: 11px;
        }
      }
    }

    .endDate {
      display: flex;
      gap: 5px;
      align-items: center;
      line-height: 100%;

      .date {
        font-size: 15px;
        font-weight: $font-weight-bold;

        @media (max-width: $media-small) {
          font-size: 12px;
        }

        &:before {
          content: "終了まであと";
          font-size: 12px;
          font-weight: $font-weight-default;

          @media (max-width: $media-small) {
            font-size: 10px;
          }
        }
      }
    }
  }


  .drop-area {
    @media (min-width: $media-small) {
      border-radius: 10px;
      border: 1px solid #ccc;
      padding: 12px 40px;
    }
  }


  #edit-email-send {
    p {
      margin: 12px auto;

      @media (min-width: $media-small) {
        width: 600px;
      }
    }

    #confirm-now-mail {
      background-color: $bg-gray;
      padding: 16px 0;

      span:first-child {
        margin-bottom: 8px;
      }
    }
  }


} // .l-renewal-content